var offCanvas = (function(){
	var el = document.getElementsByClassName('menu-toggle')[0];
	return {
		init: function(){
			el.addEventListener('click',function(){
				document.documentElement.classList.toggle('nav-open')
			})
		}
	}
})();
offCanvas.init();