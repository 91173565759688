var Footer = (function(){
	var els = document.querySelectorAll('footer .col_title');
	var findAncestor = function(el, cls) {
	    while ((el = el.parentElement) && !el.classList.contains(cls));
	    return el;
	};
	return{
		init: function(){
			[].forEach.call(els, function(el) {
				el.addEventListener('click',function(e){
					var par = findAncestor(el,'col_tall');
					open = false;
					if (par.classList.contains('active'))
						open = true;
					
					if (document.querySelector('.col_tall.active'))
						document.querySelector('.col_tall.active').classList.remove('active');

					if (!open)
						par.classList.add('active');
					
					
				})
			});
		}
	}
})();
Footer.init();