var Touch = (function(){
	var is_touch_device = function() {
		 return (('ontouchstart' in window)
	      || (navigator.MaxTouchPoints > 0)
	      || (navigator.msMaxTouchPoints > 0));
	}
	return{
		init: function(){
			if (!is_touch_device()){
				document.documentElement.classList.remove('touch');
				document.documentElement.classList.add('no-touch');
			}
		}
	}
})();
Touch.init();