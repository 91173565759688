var Header = (function(){
  var el = document.querySelector('header.row');
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  var wrapper = document.body;
  var handler = function(){
    var scrollTop = parseInt(document.documentElement.scrollTop || document.body.scrollTop || 0);
    if (scrollTop > 0){
      wrapper.classList.add('header-active')
      el.classList.add('active')
    }else{
      wrapper.classList.remove('header-active')
      el.classList.remove('active')
    }
  }
  return{
    init: function(){
      window.addEventListener('scroll', handler);
      if (iOS){
        window.addEventListener('touchend', handler);  
      }
      
      handler();
    }
  }
})();
// console.log(document.documentElement.classList);
if (!document.documentElement.classList.contains('ie')){
  Header.init();  
}
