

var subNav = (function(){
	var els = document.querySelectorAll('.menu-item-has-children:not(.current_page_item) > a');
	var findAncestor = function(el, cls) {
	    while ((el = el.parentElement) && !el.classList.contains(cls));
	    return el;
	};
	return {
		init: function(){
			[].forEach.call(els, function(el) {
				el.addEventListener('click',function(e){
					e.preventDefault();
					el.parentNode.querySelector('.sub-menu').classList.toggle('active');
				})
			});
		}
	}
})();
subNav.init();