class Lexicon{
  constructor(){
    this.eventsSetup();

    this.pageTitle = document.title;
    this.location = window.location.href.split('#')[0];

    let modalTempl = document.createElement('div');
    modalTempl.classList.add('modal-wrapper');
    modalTempl.setAttribute("role","dialog");

    let modalInner = document.createElement('div');
    modalInner.classList.add('modal-inner');

    modalTempl.appendChild(modalInner);

    this.modalTempl = modalTempl;

  };
  eventsSetup(){
    let links = document.querySelectorAll('a.lexicon');
    [].forEach.call(links, (l) => {
      l.addEventListener('click',(e)=>{
        e.preventDefault();
        [].forEach.call(document.getElementsByClassName('modal-active'), (m) => {
          m.classList.remove('modal-active');
        });
        if (history.pushState) {
          history.pushState({}, l, l.href);
        }
        if (document.querySelector('.modal-wrapper[data-word='+l.dataset.word+']')){
          this.openModal(document.querySelector('.modal-wrapper[data-word='+l.dataset.word+']'))
        }else{
          this.createModal(l.dataset.word)
        }
      });
    });
  };
  openModal(lexicon){
    lexicon.classList.add('modal-active');
  }
  createModal(lexicon){
    let lib = this;
    let modal = lib.modalTempl.cloneNode(true);
    
    modal.querySelector('.modal-inner').innerHTML = '<p>Loading</p>';

    modal.classList.add('modal-active');
    modal.dataset.word = lexicon;

    document.body.appendChild(modal);

    let request = new XMLHttpRequest();
    request.open('GET', '/wp/wp-admin/admin-ajax.php?action=lexicon&word='+lexicon, true);

    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        let resp = request.responseText;
        let inner = modal.querySelector('.modal-inner');
        inner.innerHTML = '<div class="modal-content">'+resp+"</div>";
        inner.focus();
        let close = document.createElement('a');
        close.setAttribute('href','#');
        close.textContent = 'close';
        close.classList.add('modal-close');
        close.addEventListener('click', (e) => {
          e.preventDefault();
          modal.classList.remove('modal-active');
          if (history.pushState) {
            history.pushState({foo: 'bar'}, lib.pageTitle, lib.location);
          }
        });
        inner.appendChild(close);

        lib.eventsSetup();

      }else{
        alert('failed');
        modal.querySelector('.modal-inner').innerHTML = '';
      }
    };

    request.send();
  };
};
let modal = new Lexicon();
